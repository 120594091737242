<template>
  <div>
    <el-card class="box-card">
      <div class="clearfix" slot="header">
        <el-link @click="getInfo" class="title">基本信息
        </el-link>
        <el-link @click="editDetail" class="title" icon="el-icon-edit"
                 style="margin-left: 10px" v-if="userPermissions.indexOf('kol_info_save') !== -1">
        </el-link>
      </div>
      <div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-card shadow="never" style="height: 411px">
              <el-row :gutter="24">
                <el-col :span="5" style="text-align: center">
                  <el-image
                      :src="form.avatar"
                      style="width: 80px; height: 80px; border-radius: 50%"></el-image>
                </el-col>

                <el-col :span="19">
                  <el-form :label-position="labelPosition" :model="form" class="newForm"
                           label-width="100px "
                           ref="form">
                    <el-form-item label="红人昵称">
                      <span>{{ form.nickname }}</span>
                    </el-form-item>
                    <el-form-item label="红人性别">
                      <span>{{ form.sex == '1' ? '男' : form.sex == '2' ? '女' : '其他' }}</span>
                    </el-form-item>
                    <el-form-item label="红人肤质">
                      <span>{{ form.skin_type }}</span>
                    </el-form-item>
                    <el-form-item label="账号标签">
                      <span>{{
                          form.account_label && form.account_label[0] != 'Array' ? form.account_label.toString() : ''
                        }}</span>
                    </el-form-item>
                    <el-form-item label="擅长">
                      <span>{{ form.skill_id == 22 ? '图文' : form.skill_id == 23 ? '视频' : '全' }}</span>
                    </el-form-item>
                    <el-form-item>
                      <span>{{ form.area }}</span>
                      <template slot="label">
                        <i class="el-icon-place"> </i> 地区
                      </template>
                    </el-form-item>
                    <el-form-item label="地址">
                      <span>{{ form.address }}</span>
                    </el-form-item>

                    <el-form-item label="简介">
                      <span>{{ form.intro }}</span>
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
              <el-row>
                <span>开通平台：</span>
                <div :key="key" style="display: inline"
                     v-for="(platform, key) in platformsImgs">
                  <el-image
                      :src="`${platform.logo_url}`"
                      fit="contain" style="width: 20px; height: 20px; padding: 5px 5px 0 5px">
                  </el-image>
                </div>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card :offset="1" shadow="never" style="height: 411px">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form :label-position="labelPosition" :model="form" class="newForm"
                           label-width="100px " ref="form"
                           size="mini">
                    <el-form-item label="所属机构">
                      <span>{{ form.organ_name }}</span>
                    </el-form-item>
                    <el-form-item label="配合度">
                      <span>{{ form.fit }}</span>
                    </el-form-item>

                    <el-form-item label="提报次数">
                      {{ form.r_num }}
                    </el-form-item>
                    <el-form-item label="提报成功率">
                      <span>{{ `${form.rs_rate ? form.rs_rate + '%' : '暂无'}` }}</span>
                    </el-form-item>
                    <el-form-item label="录入时间">
                      <span>{{ form.created_at }}</span>
                    </el-form-item>
                    <el-form-item label="联系方式">
                      <span>{{
                          form.contact_mode.mode == '' ? '' : form.contact_mode.mode == 'mobile' ? '手机：' : '微信：'
                        }}{{ form.contact_mode.value ? form.contact_mode.value : '暂无' }}</span>

                      <!--                                            <span>{{ form.contact_mode.mode=='mobile'?'手机号：':'微信号：' }}{{ form.contact_mode.value }} </span>-->
                    </el-form-item>
                    <el-form-item label="备注">
                      <span>{{ form.remark }}</span>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="12">
                  <el-form :label-position="labelPosition" :model="form" class="newForm"
                           label-width="100px " ref="form"
                           size="mini">
                    <el-form-item label="资料来源">
                      <span>{{ form.source }}</span>
                    </el-form-item>
                    <el-form-item label="合作次数">
                      <span>{{ form.co_num }}</span>
                    </el-form-item>
                    <el-form-item label="最近一次合作时间">
                      <span>{{ form.last_co_time }}</span>
                    </el-form-item>
                    <el-form-item label="曾经合作过的品牌">
                      <span>{{ form.co_brand }}</span>
                    </el-form-item>
                    <el-form-item label="最后更新时间">
                      <span>{{ form.updated_at }}</span>
                    </el-form-item>
                    <el-form-item label="媒介">
                      <span>{{ form.referrer }}</span>
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card :offset="1" shadow="never" style="height: 411px">
              <el-table :data="form.fans_info" border stripe>
                <el-table-column align="center" label="粉丝画像">
                  <el-table-column align="center" label="性别">
                    <template slot-scope="{row}">
                      <span>{{ row.sex == '1' ? '男' : row.sex == '' ? '' : '女' }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="比例%" prop="rate">
                  </el-table-column>
                  <el-table-column align="center" label="人群标签TOP" prop="label">
                    <template slot-scope="scope">
                   <span>
                  {{ scope.row.label ? scope.row.label.toString() : scope.row.label }}
</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="主要年龄" prop="master_age">
                    <template slot-scope="scope">
                   <span>
                  {{ scope.row.master_age ? scope.row.master_age.toString() : scope.row.master_age }}
</span>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <br>
    <KolPlatform :kolInfo="{id:form.id,nickname:form.nickname}" :platformList="form.opened_platforms"
                 v-if="form.opened_platforms.length!=0&&userPermissions.indexOf('kol_info_platform') !== -1"
    ></KolPlatform>
    <br>
    <KolHistory :kolId="Number(kolId)"
                v-if="userPermissions.indexOf('kol_info_history') !== -1&&kolId"></KolHistory>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import KolHistory from './KolHistory'
import KolPlatform from './KolPlatform'


export default {
  name: 'ArtistIntro',
  components: { KolHistory, KolPlatform },
  data() {
    return {
      labelPosition: 'left',
      kolId: null,
      form: {
        name: '',
        co_brand: '',
        region: '',
        address: '',
        sex: '',
        skin_type: '',
        type: '',
        intro: '',
        r_num: 1,
        co_num: 1,
        remark: '',
        created_at: '',
        rs_rate: '',
        last_co_time: '',
        updated_at: '',
        referrer: '',
        fans_info: [{
          sex: '',
          rate: '',
          label: [],
          master_age: []
        }],
        contact_mode: {
          mode: '',
          type: ''
        },
        opened_platforms: []
      },
      //平台全部图标合集
      platforms: [],
      //是否开通了抖音/小红书平台数据合集
      platform: []
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    // 计算属性的 getter
    //平台icon图
    platformsImgs: function() {
      return this.platforms.filter(item => {
        return this.form.opened_platforms.includes(item.code)
      })
    }
  }
  ,
  methods: {
    editDetail() {
      this.$router.push({
        name: 'kol_add', params: { id: this.kolId, type: this.form.opened_platforms[0] }
      })
    },

    async getInfo() {
      let { info } = await this.$api.getKoldetails({ id: this.kolId })
      this.$nextTick(() => {
        this.form = info
      })
    }
    ,
    //get基本信息
    async infoData() {
      this.tableLoading = true
      await this.getInfo()
      let { list } = await this.$api.getCategoryListByIdentify(`skill-category`)
      this.$nextTick(() => {
        this.platform = []
        this.category = list
      })
    }
    ,

    //get已开通平台icon
    async getPlatforms() {
      // 获取最新平台信息
      let { list } = await this.$api.getPlatformAll({ status: 1 })
      this.$nextTick(() => {
        this.platforms = list
        localStorage.setItem('platforms', JSON.stringify(list))
      })
    }
  },

  mounted() {
    this.kolId = this.$route.params.id
    this.columns = this.titokColumns
    this.infoData()
    this.getPlatforms()
  }

}
</script>

<style lang="scss">
.title {
  font-size: 18px;
  font-weight: 800;
}

.newForm {
  /*border: 1px solid red;*/

  .el-form-item__label {
    color: black;
    font-weight: 600;
  }

  .el-form-item--mini.el-form-item {
    margin-bottom: 13px;
  }
}

.platforms-tab .el-tabs__item {
  display: block;
  margin: 1px 1px;
  line-height: 25px;
  font-size: 1rem;
  /*word-wrap: break-word;*/
  writing-mode: horizontal-tb;
  height: 80px;
  padding-left: 10px;
  padding-top: 10px;
  overflow-x: hidden;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #1890ff;
  background-color: #FFFFFF;
  border-right-color: #DCDFE6;
  border-left-color: #DCDFE6;
  font-weight: 600;
  font-size: 14px;
}

</style>
