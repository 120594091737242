<template>
  <el-card class="box-card" v-if="currentTabs.length != 0"
  >
    <div class="clearfix" slot="header">
      <el-link @click="getKolPlatformData" class="title">平台数据
      </el-link>

    </div>
    <div>
      <el-row :gutter="1">
        <el-col :span="24">
          <el-tabs :stretch="true" :style="{height:ismore?'220px':'auto'}" :tab-position="labelPosition"
                   :value="defaultTab"
                   @tab-click="handleClick" class="platforms-tab" type="border-card">
            <el-tab-pane :key="`${item.code}_${index}`" :label="item.name"
                         :name="`${item.code}`"
                         v-for="(item, index) in currentTabs">
              <template>
                                   <span slot="label">
                                       <div style="display: block; height: auto">
                                        <el-avatar :size="30" :src="item.logo_url" @error="true" shape="square">
                                        </el-avatar>
                                        <br/>
                                        <el-tooltip :content="`${item.name}`" class="item" effect="dark"
                                                    placement="right">
                                          <span class="platform_name">{{
                                              item.name && item.name.length > 2 ? item.name.substring(0, 2) : item.name
                                            }}
                                          </span>
                                        </el-tooltip>
                                      </div>
                                    </span>
              </template>
              <div>
                <el-table :data="platformData" :highlight-current-row="true"
                          :row-class-name="rowClassName"
                          border
                          class="default-table"
                          element-loading-spinner="el-icon-loading"
                          element-loading-text="数据加载中……"
                          key="table1" ref="refTblLiveDataRecord"
                          style="width: 100%" v-loading="tableLoading">
                  <template v-for="(column,index) in columns">
                    <template v-if="column.component==='select'">
                      <el-table-column :align="column.align?column.align:'center'"
                                       :disabled="`${column.disabled}`"
                                       :header-align="column.header_align?column.header_align:'center'"
                                       :key="index"
                                       :label="`${column.title}`"
                                       :min-width="`${column.width}`"
                                       :prop="`${column.value}`">
                        <template slot-scope="{row}">
                          <el-select
                              v-if="!row.isedit"
                              v-model="row[column.value]">
                            <el-option
                                :key="index+'is_cart'"
                                :value="1"
                                label="是">
                            </el-option>
                            <el-option
                                :key="index+'is_cart2'"
                                :value="2"
                                label="否">
                            </el-option>
                          </el-select>
                          <span v-else> {{ row[column.value] == 1 ? '是' : '否' }} </span>
                        </template>
                      </el-table-column>
                    </template>
                    <template v-if="column.component==='cascader'">
                      <el-table-column :align="column.align?column.align:'center'"
                                       :disabled="`${column.disabled}`"
                                       :header-align="column.header_align?column.header_align:'center'"
                                       :key="index+'cascader'"
                                       :label="`${column.title}`"
                                       :min-width="`${column.width}`"
                                       :prop="`${column.value}`"
                      >
                        <template slot-scope="{row}">
                          <el-select
                              v-if="!row.isedit"
                              v-model="row[column.value]">
                            <el-option :key="index+'category'"
                                       :label="item.display_name"
                                       :value="item.id"
                                       v-for="(item,index) in category">
                            </el-option>
                          </el-select>
                          <span v-else>  {{ row[column.value] == 22 ? '图文' : row[column.value] == 23 ? '视频' : '全' }}   </span>
                        </template>
                      </el-table-column>
                    </template>
                    <template v-if="column.children">
                      <el-table-column :align="column.align?column.align:'center'"
                                       :header-align="column.header_align?column.header_align:'center'"
                                       :key="index+'keys'"
                                       :label="`${column.title}`"
                                       :min-width="`${column.width}`"
                                       :prop="column.value"
                                       width="500">
                        <el-table-column
                            :align="column.align?column.align:'right'"
                            :header-align="column.header_align?column.header_align:'right'"
                            :key="item.value"
                            :label="item.title"
                            :min-width="`${column.width}`" :prop="item.value"
                            v-for="item in column.children">
                          <editable-cell
                              :click-edit="false"
                              :is-disabled="column.disabled?column.disabled:false"
                              :is-input-number="!column.isnumber&&column.isnumber!=undefined?column.isnumber:true"
                              :show-input="!row.isedit"
                              slot-scope="{row}"
                              v-model="row[item.value]">
                                                       <span slot="content">
                                                           <span>{{ cellfmt(item.value, row[item.value]) }}</span>
                                                           <i class="el-icon-edit float-right"
                                                              v-if="showEditIcon"></i>
                                                             </span>
                          </editable-cell>
                        </el-table-column>
                      </el-table-column>
                    </template>
                    <template v-else-if="column.component==='text'">
                      <el-table-column :align="column.align?column.align:'center'"
                                       :header-align="column.header_align?column.header_align:'center'"
                                       :key="index+'tableindex'"
                                       :label="`${column.title}`"
                                       :min-width="`${column.width}`"
                                       :prop="`${column.value}`" show-overflow-tooltip>
                        <template slot-scope="{row}">
                          {{ row[column.value] ? row[column.value] : '暂无' }}
                        </template>
                      </el-table-column>
                    </template>
                    <template
                        v-else-if="column.component!='select'&&column.component!='cascader'">
                      <el-table-column :align="column.align?column.align:'center'"
                                       :header-align="column.header_align?column.header_align:'center'"
                                       :key="index+'tableindex'"
                                       :label="`${column.title}`"
                                       :min-width="`${column.width}`"
                                       :prop="`${column.value}`" show-overflow-tooltip>
                        <editable-cell
                            :click-edit="false"
                            :is-disabled="column.disabled?column.disabled:false"
                            :is-input-number="!column.isnumber&&column.isnumber!=undefined?column.isnumber:true"
                            :show-input="!row.isedit"
                            slot-scope="{row}"
                            v-model="row[column.value]">
                          <span slot="content">
                              <a target="_blank" :href="row[column.value]"
                                 v-if="column.title==='链接'&&row[column.value]">{{ row[column.value] }}</a>
                                <span v-else>{{ row[column.value] }}</span>&nbsp;&nbsp;
                                       <i class="el-icon-edit float-right" v-if="showEditIcon"></i>
                                </span>
                        </editable-cell>
                      </el-table-column>
                    </template>
                  </template>
                  <el-table-column align="center" fixed="right" label="操作项" width="120">
                    <template slot-scope="{$index,row}">
                      <div v-if="!row.isedit||!row.id">
                        <el-row :gutter="5">
                          <el-col :span="12">
                            <el-link :disabled="loadingAdd" @click="saveCurrentRow(row)"
                                     type="primary">
                              <span><i class="el-icon-plus"></i>{{ loadingAdd ? '提交中' : '保存' }}</span>
                            </el-link>
                          </el-col>
                          <el-col :span="12">
                            <el-link @click="cancel(row)" type="danger"><i
                                class="el-icon-document-copy"></i>取消
                            </el-link>
                          </el-col>
                        </el-row>
                      </div>
                      <div v-else>
                        <el-link @click="handleRow(row,$index)" type="danger"><i
                            class="el-icon-edit"></i>修改
                        </el-link>
                        <el-link @click="copyRow(row,$index)" type="danger"><i
                            class="el-icon-document-copy"></i>复制并新增
                        </el-link>
                        <el-link @click="delRow(row,$index)" type="danger"><i
                            class="el-icon-delete"></i>移除
                        </el-link>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
          </el-tabs>
          <div v-if="ismore">
            <br>
            <el-row>
              <el-col :offset="1">
                <span>共</span>
                {{ platformPage.total }}条数据。当前{{
                  platformPage.current_page
                }}页，共{{ Math.ceil(platformPage.total / platformPage.page_size) }}页
              </el-col>
            </el-row>
            <br>
            <el-row>
              <el-col :offset="1">
                <el-button @click="andMoreBtn" plain type="primary">查看更多</el-button>
              </el-col>
            </el-row>
          </div>
          <el-row v-else>
            <el-col :span="24" style="text-align: right">
              <Pagination :limit.sync="platformPage.page_size"
                          :page.sync="platformPage.current_page"
                          :total="platformPage.total"
                          @pagination="getKolPlatformData"/>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </el-card>

</template>

<script>
import { editTable } from '@/components/mixins/editable'

export default {
  name: 'KolPlatform',
  mixins: [editTable],
  //
  props: {
    platformList: {
      type: Array,
      default() {
        return []
      }
    },
    kolInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      labelPosition: 'left',
      showEditIcon: false,//是否显示编辑按钮
      loadingAdd: false,
      titokColumns: [
        {
          value: 'account_id',
          title: '账号id',
          width: 100,
          component: 'el-input',
          data_type: 'integer',
          disabled: true
        },
        //
        {
          value: 'home_page_link',
          title: '链接',
          width: 100,
          component: 'el-input',
          data_type: 'integer',
          isnumber: false,
          required: true
        },
        {
          value: 'fans_total', title: '粉丝量w', width: 100, component: 'el-input', data_type: 'integer',
          required: true,
          align: 'center'
        },
        {
          value: 'like_total',
          title: '赞藏数w',
          width: 100,
          component: 'el-input',
          data_type: 'integer',
          required: true


        },

        {
          value: 'opened_star_price', title: '星图价格', width: 130, children: [
            {
              value: '20s',
              title: '20s',
              width: 130,
              component: 'el-input',
              data_type: 'integer',
              align: 'right'

            },
            {
              value: '60s',
              title: '60s',
              width: 130,
              component: 'el-input',
              data_type: 'integer',
              align: 'right'
            }
          ]
        },

        {
          value: 'not_opened_star_price', title: '非星图价格', width: 130, children: [
            {
              value: 'single',
              title: '单推',
              width: 130,
              component: 'el-input',
              data_type: 'integer',
              align: 'right'

            },
            {
              value: 'union',
              title: '合集',
              width: 130,
              component: 'el-input',
              data_type: 'integer'

            }
          ]
        },
        {
          title: '数据近30天', width: 130, children: [
            {
              value: 'month_play_total',
              title: '平均播放量w',
              width: 130,
              component: 'el-input',
              data_type: 'integer',
              required: true


            },
            {
              value: 'month_zan_total',
              title: '平均点赞w',
              width: 130,
              component: 'el-input',
              data_type: 'integer',
              required: true


            }
          ]
        },
        {
          value: 'is_cart', title: '是否可挂车', width: 100, component: 'select', data_type: 'float'

        },
        {
          value: 'remark',
          title: '备注',
          width: 200,
          component: 'el-input',
          data_type: 'integer',
          isnumber: false

        },
        {
          value: 'created_at',
          title: '录入时间',
          width: 100,
          align: 'center',
          disabled: true,
          data_type: 'string',
          component: 'text'
        },
        {
          value: 'updated_at',
          title: '最后一次更新时间',
          width: 100,
          align: 'center',
          disabled: true,
          data_type: 'string',
          component: 'text'

        }

      ],
      xhsColumns: [
        {
          value: 'account_id',
          title: '账号id',
          width: 100,
          component: 'el-input',
          data_type: 'integer',
          disabled: true

        },
        {
          value: 'home_page_link',
          title: '链接',
          width: 100,
          component: 'el-input',
          data_type: 'integer',
          isnumber: false,
          required: true

        },
        {
          value: 'fans_total', title: '粉丝量w', width: 100, component: 'el-input', data_type: 'integer',
          required: true
        },
        {
          value: 'like_total',
          title: '赞藏数w',
          width: 100,
          component: 'el-input',
          data_type: 'integer',
          required: true
        },
        {
          value: 'is_report', title: '是否可报备', width: 100, component: 'select', data_type: 'float'
        },
        {
          value: 'skill_id', title: '擅长', width: 100, component: 'cascader', data_type: 'float'
        },
        {
          value: 'opened_star_price', title: '图文价格', width: 130, children: [
            {
              value: 'image_text_price.reported',
              title: '报备',
              width: 130,
              component: 'el-input',
              data_type: 'integer'
            },
            {
              value: 'image_text_price.not_reported',
              title: '非报备',
              width: 130,
              component: 'el-input',
              data_type: 'integer'
            }
          ]
        },

        {
          value: 'not_opened_star_price', title: '视频价格', width: 130, children: [
            {
              value: 'video_price.reported',
              title: '报备',
              width: 130,
              component: 'el-input',
              data_type: 'integer'
            },
            {
              value: 'video_price.not_reported',
              title: '非报备',
              width: 130,
              component: 'el-input',
              data_type: 'integer'
            }
          ]
        },
        { value: 'remark', title: '备注', width: 200, component: 'el-input', data_type: 'integer', isnumber: false },

        {
          value: 'created_at',
          title: '录入时间',
          width: 100,
          align: 'center',
          disabled: true,
          data_type: 'string'

        },
        {
          value: 'updated_at',
          title: '最后一次更新时间',
          width: 100,
          align: 'center',
          disabled: true,
          data_type: 'string'
        }
      ],
      columns: [],
      urlList: [
        {}
      ],
      platformData: [
        { isedit: true }
      ],
      category: [],

      platformPage: { total: 0 },

      tableLoading: true,
      platformName: '',
      ismore: true,
      //当前支持的平台数据
      tabRange: ['douyin', 'xiaohongshu'],
      currentTabs: []
    }
  },
  computed: {
    defaultTab: function() {
      return this.currentTabs[0] ? this.currentTabs[0].code : ''
    }
  },

  methods: {
    //get已开通平台icon
    async getPlatforms() {
      // 获取最新平台信息
      let { list } = await this.$api.getPlatformAll({ status: 1 })
      let currentTabs = []
      let platforms = []
      this.$nextTick(() => {
        list.forEach(item => {
          this.tabRange.indexOf(item.code) > -1 ? platforms.push(item) : ''
        })
        platforms.forEach((item) => {
              if (this.platformList.indexOf(item.code) > -1) {
                currentTabs.push(item)
              }
            }
        )
        if (currentTabs.length > 0) {
          this.currentTabs = currentTabs
          this.platformName = currentTabs ? currentTabs[0].code : ''
          this.platformName === 'xiaohongshu' ? this.columns = this.xhsColumns : this.columns = this.titokColumns
          this.getKolPlatformData()
          this.getCategory()
        }

      })
    },

    //get平台数据表单
    async getKolPlatformData() {
      this.tableLoading = true
      let searchData = {
        kol_id: this.kolInfo.id,
        type: this.platformName,
        page_size: this.platformPage.page_size || 1,
        current_page: this.platformPage.current_page
      }
      // //已录入的数据
      let { list, page_info } = await this.$api.getKolplatform(searchData)
      this.$nextTick(() => {
            this.platformPage = page_info
            if (list.length) {
              //如果有数据 数据整理
              if (this.platformName === 'douyin') {
                //抖音字段
                list.forEach((item) => {
                  let data = item.price_json
                  item.account_id = this.kolInfo.nickname
                  item['20s'] = data.opened_star_price['20s'] || 0
                  item['60s'] = data.opened_star_price['60s'] || 0
                  item['single'] = data.not_opened_star_price['single'] || 0
                  item['union'] = data.not_opened_star_price['union'] || 0
                  item['isedit'] = true
                })
              } else {
                //小红薯字段
                list.forEach((item) => {
                  let data = item.price_json
                  item.account_id = this.kolInfo.nickname
                  item['image_text_price.reported'] = data.image_text_price['reported'] || 0
                  item['image_text_price.not_reported'] = data.image_text_price['not_reported'] || 0
                  item['video_price.reported'] = data.video_price['reported'] || 0
                  item['video_price.not_reported'] = data.video_price['not_reported'] || 0
                  item['isedit'] = true
                })
              }
              this.platformData = list
            } else {
              //如果没有数据
              this.platformData =
                  [{
                    kol_id: this.kolInfo.id, //红人id
                    platform_code: this.platformName, //平台code
                    platform_name: this.platformName === 'douyin' ? '抖音' : '小红书', //平台code
                    account_id: this.kolInfo.nickname, //账号id
                    is_cart: 1
                  }]
            }
          }
      )
      this.tableLoading = false
    }
    ,
    //获取擅长下拉
    async getCategory() {
      this.tableLoading = true
      let { list } = await this.$api.getCategoryListByIdentify(`skill-category`)
      this.$nextTick(() => {
        this.platform = []
        this.category = list
        this.tableLoading = false
      })
    },
    cellfmt(field, val) {
      if (['month_play_total', 'month_zan_total'].indexOf(field) > -1) {
        return val
      } else {
        return this.moneyFormat(val)
      }
    },
    // 平台数据部分----start

    //平台新增数据
    async addInfoData(row) {
      let SearchCondition = this.handleSearchCondition(row)
      let id = await this.$api.saveKolplatform(SearchCondition)
      if (id) {
        this.$notify.success('添加成功')
        this.platformPage.page_size = 1
        await this.getKolPlatformData()
        this.ismore = true
      }
    }
    ,
    handleClick(row) {
      //切换tab
      this.tableLoading = true
      this.platformName = row.name
      this.platformName === 'xiaohongshu' ? this.columns = this.xhsColumns : this.columns = this.titokColumns
      this.ismore = true
      this.platformPage.page_size = 1
      this.getKolPlatformData()
    }
    ,

    //保存单元格数据
    async saveCell(row) {
      if (row.id) {
        if (row.created_at) {
          row.created_at = row.created_at.slice(0, 10)
        }
        let SearchCondition = this.handleSearchCondition(row)
        //如果有ID是 修改
        let id = await this.$api.saveKolplatform(SearchCondition)
        if (id) {
          this.$notify.success('修改成功！')
          row.isedit = true
          await this.getKolPlatformData()
        }
      }
    }
    ,
    //复制一行数据（拷贝）
    copyRow(row) {
      let copyR = { ...row }
      copyR.isedit = false
      delete copyR.id
      delete copyR.created_at
      delete copyR.updated_at

      if (!this.platformData[0].id) {
        this.$notify.warning('已经有一行数据在新增啦~')
      } else {
        this.platformData.unshift(copyR)
      }
    }
    ,
    //取消
    cancel(row) {
      if (row.id) {
        row.isedit = true
      } else {
        delete this.platformData[0]
        this.getKolPlatformData()
      }
    }
    ,
    //修改按钮
    handleRow(row) {
      row.isedit = false
    }
    ,
    //删除数据
    delRow(row, index) {
      this.$confirm(`此操作将永久删除该行数据（ID:${row.id}）, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (row.id) {
          this.$api.delKolplatform({ id: row.id })
        }
        this.platformData.splice(index, 1)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getKolPlatformData()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
    ,
    //保存数据判断处理
    handleSearchCondition(row) {
      let newData = {}
      //抖音 小红书判断
      if (this.platformName === 'douyin') {
        newData = {
          opened_star_price: {
            '20s': row['20s'], //20s
            '60s': row['60s']
          }, //星图价格
          not_opened_star_price: {
            single: row['single'], //单推
            union: row['union'] //合集
          } //非星图价格
        }
      } else {
        newData = {
          image_text_price: {
            reported: row['image_text_price.reported'],
            not_reported: row['image_text_price.not_reported']
          }, //星图价格
          video_price: {
            reported: row['video_price.reported'],
            not_reported: row['video_price.not_reported']
          } //非星图价格
        }
      }
      row.remark == null ? row.remark = '' : row.remark
      row.price_json = newData
      delete row.created_at
      delete row.updated_at
      delete row.isedit
      return row
    }
    ,
    //点击更多
    andMoreBtn() {
      this.ismore = false
      this.platformPage.page_size = 10,
          this.platformPage.current_page = 1
      this.getKolPlatformData()
    }

  }
  ,
  mounted() {
    this.getPlatforms()
    // this.currentTab()
  }
}
</script>

<style scoped>

</style>
