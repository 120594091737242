<template>
    <el-card class="box-card">
        <div class="clearfix" slot="header">
            <el-link @click="getHistory" class="title">合作历史</el-link>
        </div>
        <el-row>
            <el-col :span="24">
                <el-table :data="historyData" border stripe>
                    <el-table-column align="center" label="id" type="index" width="50"></el-table-column>
                    <el-table-column align="center" label="平台" prop="kol_platform.platform_name"></el-table-column>
                    <el-table-column align="center" label="粉丝量W" prop="kol_platform.fans_total"></el-table-column>
                    <el-table-column align="center" label="赞藏数W" prop="kol_platform.like_total"></el-table-column>
                    <el-table-column align="center" label="提报日期" prop="project.report_time"></el-table-column>
                    <el-table-column align="center" label="项目名称" prop="project.name"></el-table-column>
                    <el-table-column align="center" label="合作品牌" prop="project.brand"></el-table-column>
                    <el-table-column align="center" label="合作日期" prop="co_time"></el-table-column>
                    <el-table-column align="right" label="成本价" prop="cost_price"></el-table-column>
                    <el-table-column align="right" label="成交金额" prop="trans_price"></el-table-column>
                    <el-table-column align="center" label="利润率" prop="profit_rate">
                        <template slot-scope="{row}">
                            <span>{{ row.profit_rate ? row.profit_rate+'%' : '' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-col :span="24" style="text-align: right">
            <Pagination :limit.sync="historyPage.page_size"
                        :page.sync="historyPage.current_page"
                        :total="historyPage.total"
                        @pagination="getHistory"/>
        </el-col>
    </el-card>

</template>

<script>
  export default {
    name: 'KolHistory',
    data() {
      return {
        // 分页信息
        historyData: [],
        historyPage: {
          total: 0,
          page_size: 0,
          current_page: 0
        }
      }
    },
    props: {
      kolId: {
        type: Number,
        default: 1
      }
    },
    methods: {
      async getHistory() {
        //合作历史list
        let searchCondition = {
          page_size: this.historyPage.page_size || 2,
          current_page: this.historyPage.current_page || 1
          , id: this.kolId
        }
        let { list, page_info } = await this.$api.getKolhistory(searchCondition)
        this.$nextTick(() => {
          this.historyData = list
          this.historyPage = page_info
        })
      }


    },
    mounted() {
      this.getHistory()
    }

  }
</script>

<style scoped>

</style>